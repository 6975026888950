<template>
  <b-container
    class="mb-5"
    fluid
  >
    <DashboardComponent />
  </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import DashboardComponent from '@/components/dashboard/Dashboard.component'

export default {
  name: 'ABMDashboard',
  components: {
    DashboardComponent
  },
  middleware: ['auth', 'abm'],

  metaInfo () {
    return {
      title: 'ABM Dashboard'
    }
  },
  mounted () {
    core.index()
  }
}
</script>
